/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v90/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.row-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
}

a {
  text-decoration: none;
  color: inherit;
}

.dataclips {
  margin-top: 20px;
}

/* Override css */
label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined {
  background: #3c3c3c;
  padding: 6px;
  border-radius: 5px;
  color: #f1a2e3;
  font-weight: bold;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline {
    background: rgb(31, 31, 31);
    color: white;
    font-size: 18px;
    font-family: monospace;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #f1a2e3!important;
}
label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
    color: #f1a2e2c0
}
.MuiFormControl-root {
    margin-bottom: 20px!important;
}