.fullwidth {
    width: 100%;
}

.new-dataclip {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: auto;
    padding: 15px;
}

.new-dataclip > * {
    width: 100%;
}

.dataclip button {
    margin-right: 15px;
}