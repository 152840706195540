main {
  margin: auto;
  max-width: 1200px;
  width: auto;
  margin-top: 40px;
}
.card {
  background-color: white;
  border-radius: 5px;
}
body {
  background-color: #cccc;
}

.container {
  padding: 15px;
}

header {
  background-color: #339933;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1,h2,h3 {
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

badge {
  padding: 5px 10px;
  background-color: thistle;
  border-radius: 3px;
  margin: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
